import React from "react";
import PromotedItem from "./PromotedItem";

const PromotedBlogPosts = ({ data }) => {
    return (
        <div className="blogHeader bk-color-lightwhite">
            <div className="blogHeader__containter">
                {data.map(item => (
                    <PromotedItem item={item} />
                ))}
            </div>
        </div>
    )
}

export default PromotedBlogPosts