import React from "react";
import SectionLayout from "../../layouts/SectionLayout";
import BlogPostItem from "./BlogPostItem";

const BlogPostsComponent = ({ data }) => {

    return (
        <SectionLayout sectionName="blogposts">
            <div className="blogposts-container">
                {data.map(item => (
                    <BlogPostItem item={item}/>
                ))}
            </div>
        </SectionLayout>
    )
}

export default BlogPostsComponent