import React from "react";
import { Link } from "gatsby";
import ItemContent from "./ItemContent";

const PromotedItem = ({ item }) => {

    const data = item.data

    return (

        <Link to={item.url}>
            <div className="blogHeader__item">
                <ItemContent data={data}/>
            </div>
        </Link>
    )
}

export default PromotedItem