import React from "react";
import { Image } from "../Image/Image";


const ItemContent = ({ data }) => {


    return (
        <>
            <div className="blogHeader__item-heading">
                <p>{data.category}</p>
                <h2>{data.short_title}</h2>
                <h4>{data.title}</h4>
            </div>
            <div className="blogHeader__item-image">
                <Image src={data.hero_image} loading={"eager"} fadeIn={false} />
            </div>
        </>
    )
}


export default ItemContent
