import React from "react";
import { Link } from "gatsby";
import BlogPostContent from "./BlogPostContent";

const BlogPostItem = ({ item }) => {

    const data = item.data

    return (
        <Link to={item.url}>
            <div className="blogItem">
                <BlogPostContent data={data}/>
            </div>
        </Link>
    )
}

export default BlogPostItem