import React from 'react'
import Seo from '../components/Seo/Seo'
import PageLayout from "../layouts/PageLayout"
import BlogPageSection from '../sections/BlogPageSection'
import { graphql } from 'gatsby'


const BlogPage = ({ data }) => {

    if (!data) return null

    const pageContent = data.prismicBlogpage
    const page = pageContent.data || {}
    const blogContent = data.allPrismicBlogpost.nodes

    const promotedBlogPosts = blogContent.filter(item =>
        item.data.promoted === true
    )
    const blogPosts = blogContent.filter(item =>
        item.data.promoted === false
    )

    const content = {
        promotedBlogPosts,
        blogPosts
    }

    const metaTitle = page.meta_title
    const metaDescription = page.meta_description
    const metaKeywords = page.meta_keywords

    return (
        <PageLayout>
            <Seo
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeywords}
            />
            <BlogPageSection content={content}/>
        </PageLayout>
    )

}

export const query = graphql`
    query Blogquery($id: String){
        prismicBlogpage(id: {eq: $id}) {
            url
            uid
            lang
            id
            data {
              meta_title
              meta_keywords
              meta_description
              flag_page
            }
        }
        allPrismicBlogpost(sort: {fields: data___publication_date, order: DESC}) {
            nodes {
              id
              url
              uid
              data {
                title
                short_title
                promoted
                category
                hero_image {
                    alt
                    url
                    gatsbyImageData
                  }
              }
            }
          }
    }
`;

export default BlogPage