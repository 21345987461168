import React from "react";
import PromotedBlogPosts from "../components/PromotedBlogPosts/PromotedBlogPosts"
import BlogPostComponent from "../components/BlogPostComponent/BlogPostComponent"

const BlogPageSection = ({ content }) => {

    const {
        promotedBlogPosts,
        blogPosts
    } = content

    return (
        <>
            <PromotedBlogPosts data={promotedBlogPosts} />
            <BlogPostComponent data={blogPosts} />
        </>
    )
}


export default BlogPageSection